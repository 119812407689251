// Components
@import "./core/components/components";
@import 'trainee';

.esl-accordion { // __header, __body, __item
    &__header {
        padding: 15px;
        color: white;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    &__body {
        padding: 15px;
        color: white;
        font-size: 16px;
        font-weight: 300;
        display: none;
    }

    &__item {
        margin-bottom: 10px;
        background: #FFFFFF33;
        border-radius: 10px;
        overflow: hidden;
        padding: 0;
        color: white;

        &.opened {
            .esl-accordion__body {
                display: block;
            }

            .esl-accordion__arrow {
                transform: rotate(180deg);
            }
        }
    }

    &__arrow {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        transition: transform 0.3s;

        i {
            color: white;
            font-size: 24px;
        }
    }

}

.form-control {
    &--white {
        color: white;
        border-color: white;

        &:focus {
            color: white;
        }

        &::placeholder {
            color: white;
        }
    }
}

.rotate-180 {
    transform: rotate(180deg);
}

.color-esl-red {
    color: $esl-red!important;
}

.w-120px {
    width: 120px;
}

.sidebar-avatar {
    width: 43px;
    height: 43px;
    border-radius: 12px;
    background-color: $pale-red;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: .3s ease width;
}

@media (min-width: $lg) {
    [data-kt-app-sidebar-hoverable=true][data-kt-app-sidebar-minimize=on] .sidebar-avatar {
        width: 100%;
    }

    .app-sidebar:hover:not(.animating) .sidebar-avatar {
        width: 43px;
    }
}

.page-heading {
    padding: 6px 0 20px;
    margin:0;

    @media (min-width: $lg) {
        min-height: 68px;
        padding: 6px 0;
    }
}

.app-sidebar {
    .menu-link {
        font-size: 16px;
        font-weight: 500;
    }
}

.card-body#userViewDrawerBody {
    padding-left: 20px;
    padding-right: 20px;
}

i.la {
    font-size: 1em;
    color: inherit;
}

.user-info-list {
    margin: 0;
    padding:0;

    &__item {
        list-style-type: none;
        padding: 10px 0;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid rgb(6 21 43 / 10%);
        color: #515b6b;
    }

    &__icon {
        width: 24px;
        height: 24px;

        i {
            vertical-align: top;
            font-size: 24px;
            color: #06152b69;
        }
    }
}

.form-control--autocomplete {
    padding:0;
    
    .autocomplete-control {
        background:transparent;
        border:0;
        min-height: 43.56px;

        &.focused {
            border:0;
            outline: none;
            background: var(--kt-input-solid-bg-focus);
            box-shadow: none;
        }
    }
}

.cards-list-wrapper {
    display: grid;
    grid-template-rows: auto 1fr auto;
    background: #F1F4FA;
    border-radius: 5px;
    padding: 15px;

    &__content {
        overflow-y: auto;
        position: relative;
        padding-bottom: 15px;

        @media (min-width: $lg) {
            height: 50vh;
        }

        &-inner {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}

.cards-list__card-item {
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    min-height: 30px;
    display: flex;
    align-items: center;

    &.selected, &:hover {
        background: white;
    }

    &-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.btn-card-side-toggler {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &.active {
        background: #716ACA;
        color: white;
    }
}

body .rsw-editor {
    border:0;

    .rsw-toolbar {
        background: transparent;
        border-bottom: 0;

        .rsw-btn {
            width: 35px;
            height: 35px;
            color: black;
            font-size: 18px;
        }

        .rsw-separator {
            display: none;
        }
    }
    .rsw-ce {
        background: #F1F4FA;
        min-height: 250px;
    }
}

.group-item {
    border:2px solid transparent;
    font-size: 16px;
    font-weight: 500;
    color: black;

    &.active {
        border-color: #64A233;
    }

    &__card {
        border-radius: 10px;
        border: 1px solid black;
        padding: 3px 5px;

        &--reshuffle-button, &--counter {
            min-height: 75px;
            font-size: 22px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--counter {
            font-size: 36px;
        }
    }
}